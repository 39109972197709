/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
import { DeliveriesController } from './deliveries-controller';
var dependencies = ['ngMaterial', 'comcast.common.authentication', 'comcast.common.communication', 'comcast.common.navigation', 'ngMessages', 'ui.router', 'comcast-addelivery-constants', 'comcast.common.notification', 'comcast.directives', 'ui.grid', 'ui.grid.pagination', 'ui.grid.selection', 'ngSanitize'];
var defaultName = angular.module('comcast.deliveries', dependencies).config(['$stateProvider', 'addelivery.state.constants', '$mdInkRippleProvider', function ($stateProvider, stateConstants, $mdInkRippleProvider) {
  $stateProvider.state('deliveries', {
    url: '/deliveries?pageNumber',
    views: {
      mainNav: stateConstants.mainNav,
      leftNav: stateConstants.leftNav,
      content: {
        template: require('./deliveries-template.html'),
        controller: DeliveriesController,
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  });
  $mdInkRippleProvider.disableInkRipple();
}]).name;
export { defaultName as default, dependencies };