declare var angular: angular.IAngularStatic;

import { LoginService } from "../common/authentication/authentication-service";
import * as bowser from 'bowser';
import * as moment from 'moment-timezone';

export class DynamicDeliveries {
    // Private
    private PAGE_SIZE: number;
    private loadedPages: any;

    // Bindable
    public SearchParams: any;
    public sessionData: any;
    public sortObj: any;
    public bowser: any;
    public numItems: number;
    public searching: any;
    public exportUrl: string;

    static get $inject() {
        return [
            'AssetResourceFactory',
            'DeliveryResourceFactory',
            '$stateParams',
            '$timeout'
        ];
    }

    constructor (
        public AssetResourceFactory: any,
        public DeliveryResourceFactory: any,
        public $stateParams: any,
        public $timeout: any,
        public $scope: any,
        public loginService: LoginService,
        public promoOnly: Boolean,
        public corollaryInfo: any,
        public deliveryThumbs: any,
    ) {
        /* PRIVATE : DATA */
        this.SearchParams = $stateParams;
        this.PAGE_SIZE = 50;
        this.numItems = 0;
        this.searching;

        /* BINDABLE : DATA */
        this.sessionData = this.loginService.getSessionData();
        this.sortObj = {
            field: $stateParams.sortField ? $stateParams.sortField : 'date_created',
            direction: $stateParams.sortDirection ? $stateParams.sortDirection : 'ASC',
        };
        this.bowser = bowser;
        this.exportUrl = '';

        /* EVENTS */
        //Register any event listeners
        this.$scope.$on('$stateChangeSuccess', (event:any, toState:any, toParams:any) => {
            this.SearchParams = toParams;
        });

        /* Get things rolling */
        this.reloadPages_();
    }

    getItemAtIndex = (index: any, lazy?: any) => {
        var pageNumber = Math.floor(index / this.PAGE_SIZE);
        var page = this.loadedPages[pageNumber];

        if (page) {
            return page[index % this.PAGE_SIZE];
        } else if (page !== null) {
            this.fetchPage_(pageNumber);
        }
    }

    getLength = () => {
        return this.numItems;
    }

    setItemAtIndex = (index: number, item: any) => {
        var pageNumber = Math.floor(index / this.PAGE_SIZE);
        var page = this.loadedPages[pageNumber];

        // Only update the screen if the delivery is currently loaded
        if (page) {
            page[index % this.PAGE_SIZE] = item;
        }
    }

    fetchPage_ = (pageNumber: any) => {
        let vm = this;
        vm.searching = true;

        // Set the page to null so we know it is already being fetched.
        vm.loadedPages[pageNumber] = null;

        var pageOffset = pageNumber * vm.PAGE_SIZE;

        var sortField = vm.$stateParams.sortField
            ? vm.$stateParams.sortField
            : 'statusLastUpdatedDate';
        var sortDirection = vm.$stateParams.sortDirection
            ? vm.$stateParams.sortDirection
            : 'DESC';

            vm.$timeout(function () {
            // Filter out blank values, since we don't want to search by those
            for (var prop in vm.SearchParams) {
                if (vm.SearchParams.hasOwnProperty(prop)) {
                    vm.SearchParams[prop] =
                        vm.SearchParams[prop] === '' || vm.SearchParams[prop] === null
                            ? undefined
                            : vm.SearchParams[prop];
                }
            }

            vm.SearchParams.offset = pageOffset;
            vm.SearchParams.limit = vm.PAGE_SIZE;
            vm.SearchParams.sortField = sortField;
            vm.SearchParams.sortDirection = sortDirection;
            vm.SearchParams.sortType = 'delivery';

            // If this is the promo view, always show only promos
            if (vm.promoOnly) {
                vm.SearchParams.promo = true;
                vm.SearchParams.expiredFlag = false;
                vm.SearchParams.runEndDate =
                    'gte:' +
                    moment().hour(0).minute(0).second(0).format('MM-DD-YYYY h:mm:ss a');

                if (vm.SearchParams.deliveryStatus === undefined) {
                    vm.SearchParams.deliveryStatus = [
                        'WAITING_FOR_ACCEPTANCE',
                        'TRANSCODING',
                        'QUEUED_FOR_DELIVERY',
                        'DELIVERING',
                        'COMPLETED',
                        'FAILED',
                    ];
                }
            }

            // COM-11612 - this is a hack to get around the fact that title is often entered with a colon in it.
            // The colon messes up the Node parsing of the field, so pass this one like admin does
            var params = angular.copy(vm.SearchParams);
            if (vm.SearchParams.title) {
                params.title = 'like:' + vm.SearchParams.title;
            }

            // TODO: Append all of these values to the params object from $stateParams so we can send one big object
            vm.DeliveryResourceFactory.getAll(
                params,
                {},
                function success(deliveries:any) {
                    deliveries = deliveries.data;
                    vm.loadedPages[pageNumber] = deliveries.rows;
                    vm.numItems = deliveries.count;
                    vm.exportUrl = deliveries.exportUrl;
                    vm.loadedPages[pageNumber] = vm.loadedPages[pageNumber].map(
                        function (data:any) {
                            vm._getDeliveryInfo(data);
                            return data;
                        }
                    );
                    vm.searching = false;
                },
                function failure(err:any) {
                    console.log(err);
                    vm.loadedPages[pageNumber] = [];
                }
            );
        });
    }

    reloadPages_ = () => {
        /**
         * @type {!Object<?Array>} Data pages, keyed by page number (0-index).
         */
        this.loadedPages = {};

        /** @type {number} Total number of items. */
        this.numItems = 0;

        /** @const {number} Number of items to fetch per request. */
        this.PAGE_SIZE = 50;

        this.getItemAtIndex(0);
    }

    _getDeliveryInfo(delivery:any) {
        let vm = this;

        vm.DeliveryResourceFactory.getCorollaryInfo(
            { id: delivery.id },
            function success(deliveryDetails:any) {
                deliveryDetails = deliveryDetails.data;
                vm.corollaryInfo[delivery.id] = deliveryDetails;

                if (
                    deliveryDetails.Spot &&
                    deliveryDetails.Spot.ThumbnailAsset &&
                    deliveryDetails.Spot.ThumbnailAsset.AssetContent &&
                    deliveryDetails.Spot.ThumbnailAsset.AssetContent.contentUuid
                ) {
                    vm._getThumbnail(
                        deliveryDetails.Spot.isci,
                        deliveryDetails.Spot.ThumbnailAsset.AssetContent.contentUuid
                    );
                }

                if (deliveryDetails.Assets) {
                    for (let i = 0; i < deliveryDetails.Assets.length; i++) {
                        if (deliveryDetails.Assets[i].assetType === 'SPOT') {
                            var filenameFull = deliveryDetails.Assets[i].Asset.name;
                            deliveryDetails.spotDeliveryFilename = filenameFull.replace(
                                /\.[^/.]+$/,
                                ''
                            );
                            break;
                        }
                    }
                }
            },
            function failure(err:any) {
                console.log(err);
            }
        );
    };

    _getThumbnail(isci:string, uuid:string) {
        let vm = this;

        vm.AssetResourceFactory.getImageAsset(
            { uuid },
            {},
            function success(asset:any) {
                var blob = new Blob([asset.data], {
                    type: asset.config['Content-Type'],
                });
                vm.deliveryThumbs[isci] = URL.createObjectURL(blob);
            },
            function failure(err: any) {
                // Unable to retrieve the image asset for the thumbnail
            }
        );
    };
}
    