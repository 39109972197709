/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

// Declare the global values used in this Component
declare var angular: angular.IAngularStatic;

import * as moment from 'moment-timezone';

import { UpdatedDatePanelController } from '../orders/updatedDatePanel-controller';

//// CONTROLLER ////
export class DeliverySearchController {
    //Bindable
    public sessionData: any;
    public campaignPromise: any;
    public brandPromise: any;
    public searchParams: any;
    public formats: any;
    public statuses: any;
    public updatedDateSearchText: any;
    public minDate: any;
    public promoView: any;
    public isFranchise: any;
    public brands: any;
    public campaigns: any;
    public dataLoaded: any;
    public filteredDuration: any;
    public validDateSearchText: any;

    static get $inject() {
        return [
            '$location',
            '$state',
            '$stateParams',
            '$scope',
            'loginService',
            'EnumService',
            '$mdPanel',
            '$timeout',
            '$filter',
            '$q',
            'BrandsResourceFactory',
            'CampaignsResourceFactory',
            'promoOnly'
        ];
    }

    constructor(
        public $location: any,
        public $state: any,
        public $stateParams: any,
        public $scope: any,
        public loginService: any,
        public EnumService: any,
        public $mdPanel: any,
        public $timeout: any,
        public $filter: any,
        public $q: any,
        public BrandsResourceFactory: any,
        public CampaignsResourceFactory: any,
        public promoOnly: boolean
    ) {
        let vm = this;

        /* PRIVATE : DATA */
        //Declare all private variables here
        vm.sessionData = loginService.getSessionData();
        /*
            * This is the `vm` object. It is a direct reference to the controller
            * which acts as our 'view-model' in angular. It also limits our need
            * to be accessing $scope directly. */
        /*jshint validthis:true*/
        vm.campaignPromise = $q.defer();
        vm.brandPromise = $q.defer();

        /* BINDABLE : DATA */
        vm.searchParams = {};
        vm.formats = [];
        vm.statuses = [];
        vm.updatedDateSearchText = '';
        vm.minDate = new Date();
        // This will be true / false, depending on which view we are in
        vm.promoView = promoOnly;
        vm.isFranchise = vm.sessionData.franchiseCustomer && !loginService.isAdmin();
        vm.brands = [];
        vm.campaigns = [];
        vm.dataLoaded = false;
    }

    checkUpdatedDate() {
        let vm = this;

        if (!vm.updatedDateSearchText) {
            vm.searchParams.updatedDateFrom = null;
            vm.searchParams.updatedDateTo = null;
            vm.searchParams.clearedFromDate = true;
        } else if (!vm.searchParams.updatedDateFrom) {
            vm.searchParams.updatedDateFrom = null;
            vm.searchParams.clearedFromDate = true;
        } else if (!vm.searchParams.updatedDateTo) {
            vm.searchParams.updatedDateTo = null;
        } else {
            vm.searchParams.clearedFromDate = null;
        }
    }

    formatDuration() {
        let vm = this;

        vm.filteredDuration = vm.$filter('timeDuration')(vm.searchParams.duration);
    }

    search() {
        let vm = this;

        if (!vm.searchParams.updatedDateFrom) {
            vm.searchParams.clearedFromDate = true;
        } else {
            vm.searchParams.clearedFromDate = null;
        }

        if (vm.isFranchise) {
            vm.searchParams.isFranchise = true;
        }

        vm.checkUpdatedDate();

        // convert dates
        vm.convertDuration();

        // Toggle between deliveries and promos
        var searchState;
        if (vm.promoView) {
            searchState = 'promoList';
        } else {
            searchState = 'deliveryList';
        }

        vm.$state.go(searchState, vm.searchParams, {
            notify: true,
            inherit: false,
            reload: true,
        });
    }

    clear() {
        let vm = this;

        vm.searchParams = {};
        if (vm.sessionData.ignoreDateRestriction) {
            vm.updatedDateSearchText = '';
        } else {
            vm.minDate = new Date();
            if (vm.isFranchise) {
                vm.minDate = new Date(
                    vm.minDate.getFullYear(),
                    vm.minDate.getMonth() - 36,
                    vm.minDate.getDate()
                );
            } else {
                vm.minDate = new Date(
                    vm.minDate.getFullYear(),
                    vm.minDate.getMonth() - 6,
                    vm.minDate.getDate()
                );
            }
            vm.searchParams.updatedDateFrom = vm.minDate;
            vm._setUpdatedDateSearchText();
        }
        vm.validDateSearchText = '';
    }

    showUpdatedDate() {
        let vm = this;

        var position = vm.$mdPanel
            .newPanelPosition()
            .relativeTo('.updated-date-input')
            .addPanelPosition(vm.$mdPanel.xPosition.OFFSET_END, vm.$mdPanel.yPosition.CENTER);

        var updatedDatePanel = {
            attachTo: angular.element(document.body),
            controller: UpdatedDatePanelController,
            controllerAs: 'ctrl',
            disableParentScroll: true,
            template: require('./updatedDatePanel-template.html'),
            scope: vm.$scope,
            preserveScope: true,
            panelClass: 'search-popover-panel',
            position,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 2,
            onRemoving(event:any, removePromise:any) {
                vm._setUpdatedDateSearchText();
            },
            locals: {
                minDate: vm.minDate,
            },
        };

        vm.$mdPanel.open(updatedDatePanel);
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them
    _setUpdatedDateSearchText = () => {
        let vm = this;

        var returnText = '';
        if (!vm.searchParams.updatedDateFrom && !!vm.searchParams.updatedDateTo) {
            returnText =
                'before ' + moment(vm.searchParams.updatedDateTo).format('MM/DD/YYYY');
            vm.searchParams.statusLastUpdatedDate =
                'lte:' +
                moment(new Date(vm.searchParams.updatedDateTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a');
        } else if (!!vm.searchParams.updatedDateFrom && !vm.searchParams.updatedDateTo) {
            returnText =
                'after ' + moment(vm.searchParams.updatedDateFrom).format('MM/DD/YYYY');
            vm.searchParams.statusLastUpdatedDate =
                'gte:' +
                moment(new Date(vm.searchParams.updatedDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a');
            // If it has both dates
        } else if (!!vm.searchParams.updatedDateFrom && !!vm.searchParams.updatedDateTo) {
            returnText =
                moment(vm.searchParams.updatedDateFrom).format('MM/DD/YYYY') +
                ' to ' +
                moment(vm.searchParams.updatedDateTo).format('MM/DD/YYYY');
            vm.searchParams.statusLastUpdatedDate =
                'bt:[' +
                moment(new Date(vm.searchParams.updatedDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a') +
                ',' +
                moment(new Date(vm.searchParams.updatedDateTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a') +
                ']';
        }
        vm.updatedDateSearchText = returnText;
    }

    convertDuration() {
        let vm = this;

        var newDuration;
        var totalSeconds = 0;
        newDuration = vm.searchParams.duration ? vm.searchParams.duration.split(':') : [];
        if (newDuration.length > 1) {
            for (let i = 0; i < newDuration.length - 1; i++) {
                newDuration[i] *= 60;
            }
            for (var j = 0; j < newDuration.length; j++) {
                totalSeconds += parseInt(newDuration[j], 10);
            }
            vm.searchParams.duration = totalSeconds;
        } else {
            vm.searchParams.duration = newDuration[0];
        }
    }

    _loadAssociatedData = () => {
        let vm = this;

        vm.brandPromise = vm.$q.defer();
        vm.campaignPromise = vm.$q.defer();

        vm.brands = [];
        vm.campaigns = [];

        vm.BrandsResourceFactory.getAllForReceiver(
            { id: vm.sessionData.accountId },
        ).subscribe(
            (brands:any) => {
                vm.brands = brands.map(function (brand:any) {
                    return brand.name;
                });
                vm.brands = vm.brands.filter(function (brand:any, index:any) {
                    // filter out bad data and duplicate text entries, it's confusing to users and we're searching by text anyway.
                    // if sequelize wasn't such a pain about DISTINCT we wouldn't have to de-dupe like this.
                    return (
                        brand !== '' &&
                        brand !== 'undefined' &&
                        vm.brands.indexOf(brand) >= index
                    );
                });
                vm.dataLoaded = true;
                vm.brandPromise.resolve(vm.brands);
            },
            (err:any) => {
                vm.brandPromise.resolve([]);
                console.debug(err);
            }
        )

        vm.CampaignsResourceFactory.getAllForReceiver(
            { id: vm.sessionData.accountId }
        ).subscribe(
            (campaigns:any) => {
                vm.campaigns = campaigns.map(function (campaign:any) {
                    return campaign.name;
                });
                vm.campaigns = vm.campaigns.filter(function (campaign:any, index:any) {
                    // filter out bad data and duplicate text entries, it's confusing to users and we're searching by text anyway.
                    // if sequelize wasn't such a pain about DISTINCT we wouldn't have to de-dupe like this.
                    return (
                        campaign !== '' &&
                        campaign !== 'undefined' &&
                        vm.campaigns.indexOf(campaign) >= index
                    );
                });
                vm.dataLoaded = true;
                vm.campaignPromise.resolve(vm.campaigns);
            },
            (err:any) => {
                vm.campaignPromise.resolve([]);
                console.debug(err);
            }
        );
    }

    $onInit() {
        let vm = this;

        vm.searchParams = vm.$stateParams;
        vm.formatDuration();

        if (vm.promoView) {
            vm.statuses = [
                {
                    label: 'Waiting For Acceptance',
                    value: 'WAITING_FOR_ACCEPTANCE',
                },
                {
                    label: 'Transcoding',
                    value: 'TRANSCODING',
                },
                {
                    label: 'Queued',
                    value: 'QUEUED_FOR_DELIVERY',
                },
                {
                    label: 'Failed',
                    value: 'FAILED',
                },
                {
                    label: 'Delivering',
                    value: 'DELIVERING',
                },
                {
                    label: 'Delivered',
                    value: 'COMPLETED',
                },
            ];
        } else {
            vm.statuses = [
                {
                    label: 'Traffic Review',
                    value: 'PARKED',
                },
                {
                    label: 'Queued For Delivery',
                    value: 'QUEUED_FOR_DELIVERY',
                },
                {
                    label: 'Delivering',
                    value: 'DELIVERING',
                },
                {
                    label: 'Shipping',
                    value: 'SHIPPING',
                },
                {
                    label: 'Download Available',
                    value: 'DOWNLOAD_AVAILABLE',
                },
                {
                    label: 'Delivered',
                    value: 'COMPLETED',
                },
            ];
        }

        vm.EnumService.getEnum('Spot', 'format').then(
            function (formats:any) {
                vm.formats = formats;
            },
            function () {
                vm.formats = [];
            }
        );

        if (vm.isFranchise && !vm.dataLoaded) {
            // get a list of brands (models) and campaigns to display in their drop down.
            vm._loadAssociatedData();
        }

        vm.searchParams.advertiserId = vm.searchParams.advertiserId
            ? vm.searchParams.advertiserId
            : '';
        vm.searchParams.agencyId = vm.searchParams.agencyId ? vm.searchParams.agencyId : '';
        vm.searchParams.format = vm.searchParams.format ? vm.searchParams.format : '';
        vm.searchParams.status = vm.searchParams.status ? vm.searchParams.status : '';
        vm.searchParams.includeArchives = vm.searchParams.includeArchives === 'true';

        if (vm.isFranchise && vm.searchParams.brand && vm.searchParams.runningFootage) {
            vm.searchParams.runningFootage = vm.searchParams.brand;
            vm.searchParams.brand = null;
        }

        if (!vm.sessionData.ignoreDateRestriction && !vm.searchParams.clearedFromDate) {
            if (vm.isFranchise) {
                vm.minDate = new Date(
                    vm.minDate.getFullYear(),
                    vm.minDate.getMonth() - 36,
                    vm.minDate.getDate()
                );
            } else {
                vm.minDate = new Date(
                    vm.minDate.getFullYear(),
                    vm.minDate.getMonth() - 6,
                    vm.minDate.getDate()
                );
            }
            if (!vm.searchParams.updatedDateFrom) {
                vm.searchParams.updatedDateFrom = vm.minDate;
            }
        } else {
            vm.minDate = null;
        }

        vm._setUpdatedDateSearchText();
        vm.checkUpdatedDate();
    }
}
