/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

//// CONTROLLER ////
import { RequestCustomizationDialog } from './dialogs/requestCustomizationDialog';
import { DynamicDeliveries } from './deliveries-dynamicItems';
import { OperationsDestinationDialogController } from './operationsDestinationDialog-controller';
import { TCDialogController } from './tcDialog-controller';
import * as bowser from 'bowser';
import * as moment from 'moment-timezone';

//// CONTROLLER ////
export class DeliveryGridController {
    // Private

    // Bindable
    public deliveryThumbs: any = [];
    public canDownload: any = {
        deliveredSpot: false,
        originalSpot: false,
        mezzanine: false,
        quicktimeProxy: false,
        mp3: false,
        wmv: false,
        proxy: false,
        slate: false,
        qcReport: false,
        metadata: false,
        traffic: false,
    };
    public dynamicItems: any;
    public gettingAssetUuids: any = false;
    public downloadSelections: any = {
        deliveredSpot: false,
        originalSpot: false,
        mezzanine: false,
        quicktimeProxy: false,
        mp3: false,
        wmv: false,
        proxy: false,
        slate: false,
        qcReport: false,
        metadata: false,
        traffic: false,
    };
    public downloadUuids: any = {
        deliveredSpot: false,
        originalSpot: false,
        mezzanine: false,
        quicktimeProxy: false,
        mp3: false,
        wmv: false,
        proxy: false,
        slate: false,
        qcReport: false,
        metadata: false,
        traffic: false,
    };
    
    public sortObj: any;
    public selectedDeliveries: any = [];
    public lastSelection: any = -1;
    public onlyArchivesSelected: any = true;
    public displayListView: any = true;
    public fileToUpload: any = {};
    public spotDeletedCount: any = 0;
    public spotNotDeletedCount: any = 0;
    public hasProdVendor: any = false;
    public spotSentCount: any = 0;
    public spotNotSentCount: any = 0;
    public statusService: any;
    public deliveryPriorities: any = [];
    public bowser: any = bowser;
    public traffic: any = false;
    public termsAndConditions: any = null;
    public corollaryInfo: any = {};
    public sessionData: any;
    public isFranchise: any;
    public disableScroll: Boolean = false;
    public trafficRoute: string = '';

    // This will be true / false, depending on which view we are in
    public promoView: any;
    public disableRetransmit: any = true;
    public disableAccept: any = true;

    // Download links
    public deliveredSpotDownloadLink: any;
    public spotDownloadLink: any;
    public distributionMasterDownloadLink: any;
    public mezzanineDownloadLink: any;
    public qtDownloadLink: any;
    public slateDownloadLink: any;
    public metaDownloadLink: any;

    static get $inject() {
        return [
            '$cookies',
            '$mdDialog',
            '$q',
            '$scope',
            '$state',
            '$stateParams',
            '$timeout',
            '$window',
            'AssetResourceFactory',
            'EndPointService',
            'loginService',
            'NotificationService',
            'socket',
            'DeliveryResourceFactory',
            'StatusService',
            'EnumService',
            'promoOnly',
            'TermsAndConditionsResourceFactory',
            'DestinationResourceFactory',
            'OrderResourceFactory',
            'AuthenticationResourceFactory',
        ];
    }

    constructor(
        public $cookies: any,
        public $mdDialog: any,
        public $q: any,
        public $scope: any,
        public $state: any,
        public $stateParams: any,
        public $timeout: any,
        public $window: any,
        public AssetResourceFactory: any,
        public EndPointService: any,
        public loginService: any,
        public NotificationService: any,
        public socket: any,
        public DeliveryResourceFactory: any,
        public StatusService: any,
        public EnumService: any,
        public promoOnly: any,
        public TermsAndConditionsResourceFactory: any,
        public DestinationResourceFactory: any,
        public OrderResourceFactory: any,
        public AuthenticationResourceFactory: any,
    ) {

        /* set BINDABLE : DATA */
        this.dynamicItems = new DynamicDeliveries(
            this.AssetResourceFactory,
            this.DeliveryResourceFactory,
            this.$stateParams,
            this.$timeout,
            this.$scope,
            this.loginService,
            this.promoOnly,
            this.corollaryInfo,
            this.deliveryThumbs,
        );
        this.sortObj = {
            field: this.$stateParams.sortField ? this.$stateParams.sortField : 'statusLastUpdatedDate',
            direction: this.$stateParams.sortDirection ? this.$stateParams.sortDirection : 'ASC',
        };
        this.promoView = promoOnly;
        this.statusService = StatusService;
        this.sessionData = loginService.getSessionData();
        this.isFranchise = this.sessionData.franchiseCustomer;

        let vm = this;

        angular
            .element(document.getElementsByClassName('inf-scroll-container')[0])
            .on('scroll wheel touchmove touchend', function () {
                if (document.getElementsByClassName('last-card')[0]) {
                    var containerBottomLoc = document
                        .getElementsByClassName('inf-scroll-container')[0]
                        .getBoundingClientRect().bottom;
                    var cardBottomLoc = document
                        .getElementsByClassName('last-card')[0]
                        .getBoundingClientRect().bottom;
                    if (cardBottomLoc < containerBottomLoc + 500) {
                        if (vm.disableScroll) {
                            vm.disableScroll = false;
                        } else {
                            return;
                        }
                    }
                }
            });
    }

    $onInit() {
        let vm = this;

        vm.$timeout(function () {
            $('#deliveryListSearchIsci').focus();
        });
        $('.sidebar').removeClass('hidden');
        vm.$scope.home.showTgl = true;
        vm.EnumService.getEnum('DistributionOrder', 'deliveryPriority').then(
            function (priorities: any) {
                vm.deliveryPriorities = priorities;
            },
            function () {
                vm.deliveryPriorities = [];
            }
        );

        if (vm.$stateParams.orderItemId && vm.$stateParams.type && vm.$stateParams.action) {
            vm.DeliveryResourceFactory.directHandlePromos(
                {},
                {
                    orderItemId: vm.$stateParams.orderItemId,
                    type: vm.$stateParams.type,
                    action: vm.$stateParams.action,
                },
                function success() {
                    vm.NotificationService.showNotificationToast(
                        'Delivery ' + vm.$stateParams.action.toLowerCase() + ' successful'
                    );

                    // Transition to the right page depending on if it is a promo or not
                    if (vm.$stateParams.action === 'ACCEPT') {
                        delete vm.$stateParams.orderItemId;
                        delete vm.$stateParams.type;
                        delete vm.$stateParams.action;

                        vm.$state.go('promoList');
                    } else {
                        delete vm.$stateParams.orderItemId;
                        delete vm.$stateParams.type;
                        delete vm.$stateParams.action;

                        vm.$state.transitionTo(vm.$state.current, vm.$stateParams, {
                            reload: true,
                            inherit: false,
                            notify: false,
                        });
                    }
                },
                function failure(err: any) {
                    console.log(err);
                    vm.NotificationService.showNotificationToast(
                        'Failed to ' + vm.$stateParams.action.toLowerCase() + ' delivery',
                        err.data
                    );
                }
            );
        }
    }

    /* IMPLEMENTATION : BINDABLE */
    sortGrid(direction:string) {
        let vm = this;

        if (vm.sortObj.direction !== direction) {
            vm.sortObj.direction = direction;

            // Toggle between deliveries and promos
            var searchState;
            if (vm.promoView) {
                searchState = 'promoList';
            } else {
                searchState = 'deliveryList';
            }

            vm.$state.go(
                searchState,
                {
                    sortField: vm.sortObj.field,
                    sortDirection: vm.sortObj.direction,
                },
                { inherit: true }
            );
        }
    }

    sortDeliveries(field:string) {
        let vm = this;

        if (vm.sortObj.field === field) {
            vm.sortObj.direction = vm.sortObj.direction === 'ASC' ? 'DESC' : 'ASC';
        } else {
            vm.sortObj.field = field;
            vm.sortObj.direction = 'ASC';
        }

        // Toggle between deliveries and promos
        var searchState;
        if (vm.promoView) {
            searchState = 'promoList';
        } else {
            searchState = 'deliveryList';
        }

        vm.$state.go(
            searchState,
            {
                sortField: vm.sortObj.field,
                sortDirection: vm.sortObj.direction,
                sortType: 'delivery',
            },
            { inherit: true }
        );
    }

    selectDelivery(delivery:any, index:number, event:any) {
        let vm = this;

        if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
            vm.selectedDeliveries = [];
        }

        if (event.shiftKey && vm.lastSelection > -1) {
            // Do multiselect
            var toIndex = Math.max(index, vm.lastSelection);
            var fromIndex = Math.min(index, vm.lastSelection);

            for (let i = fromIndex; i <= toIndex; i++) {
                if (vm.selectedDeliveries.indexOf(i) < 0) {
                    vm.selectedDeliveries.push(i);
                }
            }
        } else if (vm.selectedDeliveries.indexOf(index) < 0) {
            vm.selectedDeliveries.push(index);
        } else {
            vm.selectedDeliveries.splice(vm.selectedDeliveries.indexOf(index), 1);
        }

        vm.onlyArchivesSelected = true;
        vm.disableRetransmit = false;
        vm.disableAccept = true;
        for (
            var deliveryIndex = 0;
            deliveryIndex < vm.selectedDeliveries.length;
            deliveryIndex++
        ) {
            if (
                vm.dynamicItems.getItemAtIndex(vm.selectedDeliveries[deliveryIndex]) &&
                vm.dynamicItems.getItemAtIndex(vm.selectedDeliveries[deliveryIndex])
                    .archivedFlag !== true
            ) {
                vm.onlyArchivesSelected = false;
            }
            if (
                vm.dynamicItems.getItemAtIndex(vm.selectedDeliveries[deliveryIndex]) &&
                vm.dynamicItems.getItemAtIndex(vm.selectedDeliveries[deliveryIndex])
                    .deliveryStatus !== 'COMPLETED'
            ) {
                vm.disableRetransmit = true;
            }
            if (
                vm.dynamicItems.getItemAtIndex(vm.selectedDeliveries[deliveryIndex]) &&
                vm.dynamicItems.getItemAtIndex(vm.selectedDeliveries[deliveryIndex])
                    .deliveryStatus === 'WAITING_FOR_ACCEPTANCE'
            ) {
                vm.disableAccept = false;
            }
        }

        vm.lastSelection = index;
    }

    openDetail(id:number) {
        let vm = this;

        var newState = 'deliveryDetail';
        if (vm.promoView) {
            newState = 'promoDetail';
        }

        angular.element(document.querySelector('.sidebar') as HTMLElement).addClass('invisible');
        vm.$state.go(newState, { id }).then(function () {
            // Apply the animation ONLY when this transition happens
            vm.$timeout(function () {
                angular
                    .element(document.querySelector('.sidebar') as HTMLElement)
                    .removeClass('invisible');
                angular
                    .element(document.querySelector('.detail-list-area .side-grid') as HTMLElement)
                    .addClass('slide-left-transition');
            }, 0);
        });
    }

    hasTrueProp(objWithProps:any) {
        for (var prop in objWithProps) {
            if (objWithProps.hasOwnProperty(prop)) {
                if (objWithProps[prop] === true) {
                    return true;
                }
            }
        }
        return false;
    }

    selectedAndHasRole(rolesToValidate:string) {
        let vm = this;

        return (
            vm.selectedDeliveries.length > 0 &&
            vm.loginService.hasPermission(rolesToValidate.split(','))
        );
    }

    hasRoles(rolesToValidate:string) {
        let vm = this;

        return vm.loginService.hasPermission(rolesToValidate.split(','));
    }

    archiveDeliveries($event:any) {
        let vm = this;

        let toArchive = false;
        for (let i = 0; i < vm.selectedDeliveries.length; i++) {
            if (
                vm.dynamicItems.getItemAtIndex(vm.selectedDeliveries[i]).archivedFlag ===
                false
            ) {
                toArchive = true;
            }
        }
        vm._archiveDeliveries(vm.selectedDeliveries, toArchive, $event);
    }

    humanReadableStatus(status:string) {
        switch (status) {
            case 'PARKED':
                return 'Traffic Review';
            case 'COMPLETED':
                return 'Delivered';
            case 'TRANSCODING':
                return 'Transcoding';
            case 'QUEUED_FOR_DELIVERY':
                return 'Queued';
            case 'WAITING_FOR_ACCEPTANCE':
                return 'Waiting For Acceptance';
            case 'DELIVERING':
                return 'Delivering';
            case 'SHIPPING':
                return 'Shipping';
            case 'DOWNLOAD_AVAILABLE':
                return 'Download Available';
            case 'FAILED':
                return 'Failed';
        }
    }

    retransmitSpots() {
        let vm = this;

        let orderItemIds:any = [];
        let expiredDeliveries = [];
        let expiredString = '<br>The following items cannot be redelivered: </br></p>';

        for (let i = 0; i < vm.selectedDeliveries.length; i++) {
            if (vm.dynamicItems.getItemAtIndex([vm.selectedDeliveries[i]]).expiredFlag) {
                expiredString +=
                    '<p>Order Item: ' +
                    vm.dynamicItems.getItemAtIndex([vm.selectedDeliveries[i]]).id +
                    '&emsp;&emsp;Reason: Delivery has expired.</br></p>';
                expiredDeliveries.push(
                    vm.dynamicItems.getItemAtIndex([vm.selectedDeliveries[i]])
                );
            } else {
                orderItemIds.push(
                    vm.dynamicItems.getItemAtIndex([vm.selectedDeliveries[i]]).id
                );
            }
        }

        //expiration popup
        if (expiredDeliveries.length > 0) {
            var confirm = vm.$mdDialog
                .alert()
                .title('Invalid Deliveries')
                .htmlContent(expiredString)
                .ariaLabel(expiredString)
                .ok('OK');

            vm.$mdDialog.show(confirm).then(function success() {
                vm._retransmitSpots(orderItemIds);
            });
        } else {
            vm._retransmitSpots(orderItemIds);
        }
    }

    requestCustomization() {
        let vm = this;

        if (vm.selectedDeliveries.length !== 1) {
            // Display notification that only one delivery can be submitted at a time
            vm.NotificationService.showNotificationToast(
                'Only one delivery can be submitted at a time for customization.'
            );
        }

        let delivery = vm.dynamicItems.getItemAtIndex([vm.selectedDeliveries[0]]);
        
        delivery.Spot = {
            isci:
                vm.corollaryInfo[delivery.id].OrderItemMetadataOverride &&
                vm.corollaryInfo[delivery.id].OrderItemMetadataOverride.isci
                    ? vm.corollaryInfo[delivery.id].OrderItemMetadataOverride.isci
                    : vm.corollaryInfo[delivery.id].Spot.isci,
            title:
                vm.corollaryInfo[delivery.id].OrderItemMetadataOverride &&
                vm.corollaryInfo[delivery.id].OrderItemMetadataOverride.title
                    ? vm.corollaryInfo[delivery.id].OrderItemMetadataOverride.title
                    : vm.corollaryInfo[delivery.id].Spot.title,
        };

        // Open a dialog (passing delivery details to it)
        vm.$mdDialog.show({
            controller: RequestCustomizationDialog.controller,
            controllerAs: 'vm',
            template: RequestCustomizationDialog.template,
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
                delivery,
            },
        });
    }

    acceptPromos() {
        let vm = this;

        let orderItems:any = [];
        let ignoreDeliveries = [];
        let errorString =
            '<br>The following items cannot be accepted for delivery: </br></p>';

        for (let i = 0; i < vm.selectedDeliveries.length; i++) {
            if (
                vm.dynamicItems.getItemAtIndex([vm.selectedDeliveries[i]])
                    .deliveryStatus !== 'WAITING_FOR_ACCEPTANCE'
            ) {
                errorString +=
                    '<p>Order Item: ' +
                    vm.dynamicItems.getItemAtIndex([vm.selectedDeliveries[i]]).id +
                    '&emsp;&emsp;Reason: Delivery is not waiting for acceptance.</br></p>';
                ignoreDeliveries.push(
                    vm.dynamicItems.getItemAtIndex([vm.selectedDeliveries[i]])
                );
            } else {
                var orderItem = vm.dynamicItems.getItemAtIndex([vm.selectedDeliveries[i]]);
                orderItems.push(orderItem);
            }
        }

        //error popup
        if (ignoreDeliveries.length > 0) {
            var confirm = vm.$mdDialog
                .alert()
                .title('Invalid Deliveries')
                .htmlContent(errorString)
                .ariaLabel(errorString)
                .ok('OK');

            vm.$mdDialog.show(confirm).then(function success() {
                vm._sortPromos(orderItems);
            });
        } else {
            vm._sortPromos(orderItems);
        }
    }

    openGridMenu($mdMenu:any, event:any, delivery:any) {
        let vm = this;

        // Reset the download selections and Uuids
        vm.downloadSelections = {
            deliveredSpot: false,
            originalSpot: false,
            mezzanine: false,
            quicktimeProxy: false,
            mp3: false,
            wmv: false,
            proxy: false,
            slate: false,
            qcReport: false,
            metadata: false,
            traffic: false,
        };
        vm.downloadUuids = {
            deliveredSpot: false,
            originalSpot: false,
            mezzanine: false,
            quicktimeProxy: false,
            mp3: false,
            wmv: false,
            proxy: false,
            slate: false,
            qcReport: false,
            metadata: false,
            traffic: false,
        };
        vm.canDownload = {
            deliveredSpot: false,
            originalSpot: false,
            mezzanine: false,
            quicktimeProxy: false,
            mp3: false,
            wmv: false,
            proxy: false,
            slate: false,
            qcReport: false,
            metadata: false,
            traffic: false,
        };
        // Set the loading flag
        vm.gettingAssetUuids = true;

        // Determine if there IS at least one traffic asset (so we can undisable the checkbox)
        vm.DeliveryResourceFactory.getTrafficAsset(
            {
                id: delivery.id,
                sourceOrderGroupId: delivery.sourceOrderGroupId,
            },
            function success(traffic:any) {
                if (traffic.data.Asset) {
                    vm.traffic = traffic.data;
                }
            },
            function failure(err:any) {
                console.log(err);
            }
        );

        var continueOn = vm.$q.defer();

        vm.DeliveryResourceFactory.makeDeliveryOriginal(
            { id: delivery.id },
            function success() {
                // It should come in here if it created a new ORIGINAL entry in order_item_asset
                // OR if an entry already existed for this delivery
                continueOn.resolve(true);
            },
            function failure(err:any) {
                // Something has gone catastrophically wrong
                console.log(err);
                continueOn.resolve(true);
            }
        );

        continueOn.promise.then(function () {
            // Retrieve the assetMap
            vm.DeliveryResourceFactory.getAssetMap(
                { id: delivery.id },
                function success(assetMap:any) {
                    var mapData = assetMap.data;
                    if (
                        vm.corollaryInfo[delivery.id].Spot &&
                        vm.corollaryInfo[delivery.id].Spot.format === 'RADIO'
                    ) {
                        vm.downloadUuids.deliveredSpot =
                            mapData.ORIGINAL && mapData.ORIGINAL.length > 0
                                ? mapData.ORIGINAL[0]
                                : false;
                        vm.downloadUuids.mp3 =
                            mapData.MEZZANINE && mapData.MEZZANINE.length > 0
                                ? mapData.MEZZANINE[0]
                                : false;
                        vm.canDownload.mp3 = vm.downloadUuids.mp3 !== false;
                    } else {
                        vm.downloadUuids.deliveredSpot =
                            mapData.SPOT && mapData.SPOT.length > 0
                                ? mapData.SPOT[0]
                                : false;
                        vm.downloadUuids.mp3 = false;
                    }

                    vm.trafficRoute = mapData.TRAFFIC;

                    vm.downloadUuids.originalSpot =
                        mapData.ORIGINAL_CONTENT && mapData.ORIGINAL_CONTENT.length > 0
                            ? mapData.ORIGINAL_CONTENT[0]
                            : false;
                    vm.downloadUuids.mezzanine =
                        mapData.MEZZANINE && mapData.MEZZANINE.length > 0
                            ? mapData.MEZZANINE[0]
                            : false;
                    vm.downloadUuids.quicktimeProxy =
                        mapData.QT_PROXY && mapData.QT_PROXY.length > 0
                            ? mapData.QT_PROXY[0]
                            : false;
                    vm.downloadUuids.slate =
                        mapData.SLATE && mapData.SLATE.length > 0
                            ? mapData.SLATE[0]
                            : false;
                    vm.downloadUuids.qcReport =
                        mapData.QC_REPORT_DATA && mapData.QC_REPORT_DATA.length > 0
                            ? mapData.QC_REPORT_DATA[0]
                            : false;
                    vm.downloadUuids.metadata =
                        mapData.METADATA && mapData.METADATA.length > 0
                            ? mapData.METADATA[0]
                            : false;
                    vm.downloadUuids.traffic =
                        mapData.TRAFFIC && mapData.TRAFFIC.length > 0
                            ? mapData.TRAFFIC[0]
                            : false;

                    vm._setDownloadPermissions(delivery);

                    vm.gettingAssetUuids = false;
                },
                function failure(err:any) {
                    console.log(err);
                }
            );
        });

        vm.TermsAndConditionsResourceFactory.findOne(
            { id: vm.sessionData.accountId, eventType: 'download' },
            {},
            function (tc:any) {
                vm.termsAndConditions =
                    tc.data && tc.data.termsAndConditions
                        ? tc.data.termsAndConditions
                        : null;
            }
        );

        $mdMenu.open(event);
    }

    async downloadAssets(delivery:any) {
        let vm = this;

        // Get the delivery's info, and today's date
        let info = vm.corollaryInfo[delivery.id];
        let today = new Date();
        let latestRightDate = new Date();
        let futureDatesFound = '<ul>';

        // Find the most future right date
        if(info.Spot.RightsDates && info.Spot.RightsDates.length > 0) {
            // If there are rights dates, start with the first one
            latestRightDate = new Date(info.Spot.RightsDates[0].validFromDate);

            for(let i = 1; i < info.Spot.RightsDates.length; i++) {
                if(new Date(info.Spot.RightsDates[i].validFromDate) > latestRightDate) {
                    latestRightDate = new Date(info.Spot.RightsDates[i].validFromDate);
                }
                if(new Date(info.Spot.RightsDates[i].validFromDate) > today) {
                    futureDatesFound += '<li>'
                        + (new Date(info.Spot.RightsDates[i].validFromDate)).toLocaleDateString("en-US")
                        + '(' + info.Spot.RightsDates[i].type + ' Rights)'
                        + '</li>';
                }
            }
        }

        if(new Date(info.DistributionOrder.flightStart) > today) {
            futureDatesFound += '<li>'
                + (new Date(info.DistributionOrder.flightStart)).toLocaleDateString("en-US")
                + '(Order Flight Start)'
                + '</li>';
        }

        if(info.Spot.runStartDate && new Date(info.Spot.runStartDate) > today) {
            futureDatesFound += '<li>'
                + (new Date(info.Spot.runStartDate)).toLocaleDateString("en-US")
                + '(Spot Run Start)'
                + '</li>';
        }

        if(
            new Date(info.DistributionOrder.flightStart) > today ||
            (info.Spot.runStartDate && new Date(info.Spot.runStartDate) > today) ||
            latestRightDate > today
        ) {
            // Cose out the list if there is one
            futureDatesFound += '</ul>';

            let confirm = vm.$mdDialog
                .confirm()
                .title('Would you like to continue?')
                .htmlContent(
                    "<p>The selected delivery is not available until the following dates for certain rights.</p><p>Are you sure you want to download it?</p>" + futureDatesFound
                )
                .ariaLabel(
                    "The selected delivery is outside of some or all of its rights windows, are you sure you want to download it?"
                )
                .ok('Continue')
                .cancel('Cancel');

            try {
                await vm.$mdDialog.show(confirm);
            } catch (e) {
                // User said no
                return false;
            }
        }

        if (vm.downloadSelections.traffic) {
            vm._downloadTrafficAsset(delivery.id);
        }
        if (vm.downloadSelections.qcReport) {
            vm.$mdDialog.show({
                controller: 'MetadataReportController',
                controllerAs: 'vm',
                template: require('../view/metadataReportDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                fullscreen: true, // For small screens only
                resolve: {
                    reportData() {
                        return vm.downloadUuids.qcReport;
                    },
                },
            });
        }

        if (
            (vm.downloadSelections.deliveredSpot ||
                vm.downloadSelections.originalSpot ||
                vm.downloadSelections.quicktimeProxy) &&
            vm.termsAndConditions &&
            !vm.loginService.isAdmin()
        ) {
            var assetType:any = [];
            if (vm.downloadSelections.deliveredSpot) {
                assetType.push('spot');
            }
            if (vm.downloadSelections.originalSpot) {
                assetType.push('original');
            }
            if (vm.downloadSelections.quicktimeProxy) {
                assetType.push('qt');
            }

            var createProxy = vm.$q.defer();

            if (vm.downloadSelections.quicktimeProxy) {
                vm.DeliveryResourceFactory.makeDeliveryProxy(
                    { id: delivery.id },
                    function success() {
                        // It should come in here if it created a new PROXY entry in order_item_asset
                        // OR if an entry already existed for this delivery
                        createProxy.resolve(true);
                    },
                    function failure(err:any) {
                        // Something has gone catastrophically wrong
                        console.log(err);
                        createProxy.resolve(true);
                    }
                );
            } else {
                createProxy.resolve(true);
            }

            // Generating an original for orderItemAsset table, even if it's not a promo, since they have the same problem
            createProxy.promise.then(function () {
                vm.$mdDialog
                    .show({
                        controller: TCDialogController,
                        controllerAs: 'vm',
                        template: require('./tcDialog-template.html'),
                        parent: angular.element(document.body),
                        clickOutsideToClose: true,
                        fullscreen: true, // For small screens only
                        locals: {
                            assetName: vm.corollaryInfo[delivery.id].Spot
                                ? vm.corollaryInfo[delivery.id].Spot.isci
                                : 'TRAFFIC',
                            terms: vm.termsAndConditions,
                            deliveredSpot: vm.downloadSelections.deliveredSpot,
                            originalSpot: vm.downloadSelections.originalSpot,
                            qtProxy: vm.downloadSelections.quicktimeProxy,
                            deliveryId: delivery.id,
                            assetType,
                        },
                    })
                    .then(
                        function accepted() {
                            vm._startDownloads(delivery);
                        },
                        function declined() {
                            vm.downloadSelections.deliveredSpot = false;
                            vm.downloadSelections.originalSpot = false;
                            vm.downloadSelections.quicktimeProxy = false;
                            vm._startDownloads(delivery);
                        }
                    );
            });
        } else {
            vm._startDownloads(delivery);
        }
    }

    parsePriority(priority:string) {
        let vm = this;

        if (vm.deliveryPriorities && vm.deliveryPriorities.length > 0) {
            return vm.EnumService.getEnumLabel(vm.deliveryPriorities, priority);
        } else {
            return '';
        }
    }

    formatValidRange(startDate:any, endDate:any) {
        if (startDate && !endDate) {
            return 'After ' + moment(startDate).format('MM/DD/YYYY');
        } else if (!startDate && endDate) {
            return 'Before ' + moment(endDate).format('MM/DD/YYYY');
        } else if (!startDate && !endDate) {
            return '';
        } else {
            return (
                moment(startDate).format('MM/DD/YYYY') +
                ' - ' +
                moment(endDate).format('MM/DD/YYYY')
            );
        }
    }

    exportToCSV() {
        let vm = this;

        // Download the export
        vm.$window.open(vm.dynamicItems.exportUrl + '&authorization=' + vm.loginService.getJwt());
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them
    _setDownloadPermissions(delivery:any) {
        let vm = this;

        var canEdit = vm.hasRoles('orderitem.modify');
        var canView = vm.hasRoles('orderitem.view');
        var isSuperUser = vm.hasRoles('superuser');

        vm.canDownload.deliveredSpot =
            !delivery.expiredFlag &&
            vm.corollaryInfo[delivery.id].Spot &&
            vm.corollaryInfo[delivery.id].Spot.format !== 'RADIO' &&
            canEdit;
        vm.canDownload.originalSpot =
            vm.corollaryInfo[delivery.id].Spot &&
            !vm.corollaryInfo[delivery.id].Spot.expiredFlag &&
            (canEdit || vm.sessionData.accountType === 'FULFILLMENT') &&
            delivery.deliveryStatus !== 'WAITING_FOR_ACCEPTANCE';
        vm.canDownload.mezzanine =
            vm.corollaryInfo[delivery.id].Spot &&
            !vm.corollaryInfo[delivery.id].Spot.expiredFlag &&
            !vm.corollaryInfo[delivery.id].Spot.nonBroadcastMedia &&
            isSuperUser &&
            delivery.deliveryStatus !== 'WAITING_FOR_ACCEPTANCE';
        vm.canDownload.quicktimeProxy =
            vm.corollaryInfo[delivery.id].Spot &&
            !vm.corollaryInfo[delivery.id].Spot.expiredFlag &&
            canView &&
            vm.corollaryInfo[delivery.id].Spot.format !== 'RADIO';
        vm.canDownload.mp3 =
            vm.corollaryInfo[delivery.id].Spot &&
            !vm.corollaryInfo[delivery.id].Spot.expiredFlag &&
            canView &&
            vm.corollaryInfo[delivery.id].Spot.format === 'RADIO';
        vm.canDownload.wmv =
            vm.corollaryInfo[delivery.id].Spot &&
            !vm.corollaryInfo[delivery.id].Spot.expiredFlag &&
            canView &&
            vm.corollaryInfo[delivery.id].Spot.format !== 'RADIO';
        vm.canDownload.proxy =
            vm.corollaryInfo[delivery.id].Spot &&
            !vm.corollaryInfo[delivery.id].Spot.expiredFlag &&
            canView &&
            vm.corollaryInfo[delivery.id].Spot.format !== 'RADIO';
        vm.canDownload.slate =
            canEdit &&
            vm.corollaryInfo[delivery.id].Spot &&
            delivery.Destination.separateSlateFlag &&
            vm.corollaryInfo[delivery.id].Spot.format !== 'RADIO';
        vm.canDownload.qcReport = canEdit;
        vm.canDownload.metadata = canEdit;
        vm.canDownload.traffic =
            canView && delivery.deliveryStatus !== 'WAITING_FOR_ACCEPTANCE';
    };

    _downloadTrafficAsset(deliveryId:number) {
        let vm = this;

        // Make request to get __sessionKey
        vm.AuthenticationResourceFactory.getSessionKey(
            {},
            function (sessionKey:any) {
                // Append __sessionKey to request to Node (instead of RE)
                vm.$window.open(
                    vm.EndPointService.downloadTrafficAssetEndPoint.replace(
                        ':id',
                        deliveryId
                    ) +
                        '?sessionKey=' +
                        sessionKey.data,
                    '_blank'
                );

                // NODE: Take __sessionKey and pipe request to RE
                // NODE: Open request directly into response to stream data back to browser
            },
            function () {
                // Unable to retrieve the session key, maybe the user is logged out.
            }
        );
        return true;
    }

    _archiveDeliveries(deliveries:any, flag:any, ev:any) {
        let vm = this;

        // Only run the command on actionable spots
        var filteredDeliveries = deliveries.filter(function (cur:any) {
            return vm.dynamicItems.getItemAtIndex(cur).archivedFlag !== flag;
        });
        var confirmOrNot;

        if (flag) {
            var confirm = vm.$mdDialog
                .confirm()
                .title('Would you like to continue?')
                .textContent(
                    "Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'."
                )
                .ariaLabel(
                    "Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'."
                )
                .targetEvent(ev)
                .ok('Continue')
                .cancel('Cancel');

            confirmOrNot = vm.$mdDialog.show(confirm);
        } else {
            confirmOrNot = vm.$q.when(true);
        }

        confirmOrNot.then(
            function () {
                // User confirmed the archive function
                // Set the flag to show whether or not there are any archived orders selected (since we're making them all the same with this function)
                vm.onlyArchivesSelected = flag;
                filteredDeliveries = filteredDeliveries.map(function (cur:any) {
                    //Have to archive the Orders on both views
                    var delivery = vm.dynamicItems.getItemAtIndex(cur);
                    delivery.archivedFlag = flag;
                    return delivery;
                });

                vm.$q.all(
                    filteredDeliveries.map(function (cur:any) {
                        return vm.DeliveryResourceFactory.save({ id: cur.id }, cur);
                    })
                ).then(
                    function success() {
                        // Delivery successfully archived
                    },
                    function failure() {
                        // Delivery Failed to be Archived
                    }
                );
            },
            function () {
                // User cancelled the archive function
            }
        );
    };

    _retransmitSpots(orderItemIds:any) {
        let vm = this;

        if (orderItemIds.length > 0) {
            vm.DeliveryResourceFactory.retransmitSpots(
                {},
                { orderItemIds },
                function success() {
                    vm.NotificationService.showNotificationToast(
                        'Spot(s) have been submitted for redelivery'
                    );
                    vm.$state.reload();
                },
                function failure(err:any) {
                    console.log(err);
                    vm.NotificationService.showNotificationToast(
                        'Spot(s) failed to be submitted for delivery',
                        err.data
                    );
                }
            );
        }
    }

    _sortPromos(orderItems:any) {
        let vm = this;

        //Sorting promos into items that have Operations Destinations, and items that don't.
        if (orderItems.length > 0) {
            vm.$q.all(
                orderItems.map(function iterator(cur:any, index:number) {
                    return vm.DestinationResourceFactory.getOperationsDestination(
                        { id: cur.Destination.id, index },
                        {}
                    ).$promise;
                })
            ).then(
                function success(arrayOfDestinations:any) {
                    var orderItemsWithOps = [];
                    var orderItemsNoOps = [];

                    for (let i = 0; i < arrayOfDestinations.length; i++) {
                        if (
                            arrayOfDestinations[i].data.opsDest &&
                            orderItems[arrayOfDestinations[i].data.index].Spot.airStatus ===
                                'RTA'
                        ) {
                            orderItems[
                                arrayOfDestinations[i].data.index
                            ].OperationsDestination = arrayOfDestinations[i].data.opsDest;
                            orderItemsWithOps.push(
                                orderItems[arrayOfDestinations[i].data.index]
                            );
                        } else {
                            orderItemsNoOps.push(
                                orderItems[arrayOfDestinations[i].data.index]
                            );
                        }
                    }

                    vm._reviewPromos(orderItemsWithOps, orderItemsNoOps);
                },
                function failure(err:any) {
                    console.log('Failed to retrieve some or all operations destinations');
                    console.log(err);
                }
            );
        }
    }

    _acceptPromos(orderItemIds:any) {
        let vm = this;

        if (orderItemIds.length > 0) {
            vm.DeliveryResourceFactory.acceptPromos(
                {},
                { orderItemIds },
                function success() {
                    vm.NotificationService.showNotificationToast(
                        'Spot(s) have been accepted for delivery'
                    );
                    vm.$state.reload();
                },
                function failure(err:any) {
                    console.log(err);
                    vm.NotificationService.showNotificationToast(
                        'Spot(s) failed to be accepted for delivery',
                        err.data
                    );
                }
            );
        }
    }

    _reviewPromos(ops:any, noOps:any) {
        let vm = this;

        if (ops.length > 0) {
            return vm.$mdDialog
                .show({
                    controller: OperationsDestinationDialogController,
                    controllerAs: 'vm',
                    template: require('./operationsDestinationDialog-template.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    fullscreen: true,
                    locals: {
                        deliveries: ops,
                    },
                })
                .then(
                    function success(buckets:any) {
                        var deliveries:any = [];
                        angular.forEach(buckets, function (bucket, key) {
                            for (let i = 0; i < bucket.length; i++) {
                                bucket[i].Destination = bucket.selectedDest;
                                delete bucket[i].selectedDest;
                                deliveries.push(bucket[i]);
                            }
                        });

                        vm.$q.all(
                            deliveries.map(function (cur:any) {
                                return vm.DeliveryResourceFactory.save({ id: cur.id }, cur);
                            })
                        ).then(
                            function success(savedDeliveries:any) {
                                //Saved all deliveries
                                vm._assembleIds(savedDeliveries, noOps);
                            },
                            function failure() {
                                // Delivery Failed to be saved
                            }
                        );
                    },
                    function canceled() {
                        //user canceled the dialog, so do nothing
                    }
                );
        } else {
            vm._assembleIds(ops, noOps);
        }
    }

    _assembleIds(ops:any, noOps:any) {
        let vm = this;

        //Going to maybe use this function to gather all the IDs of the deliveries together to send to _acceptPromos, once we've gone through the entire
        //operations destination selection process, but haven't gotten to this point yet..
        let allDeliveries:any = [];

        if (ops.length > 0) {
            // eslint-disable-next-line
            ops.map(function (curr:any) {
                allDeliveries.push(curr.id);
            });
        }

        if (noOps.length > 0) {
            // eslint-disable-next-line
            noOps.map(function (curr:any) {
                allDeliveries.push(curr.id);
            });
        }

        vm._acceptPromos(allDeliveries);
    }

    _startDownloads(delivery:any) {
        let vm = this;

        vm.NotificationService.showNotificationToast('Download(s) has started.');

        let placeholdId = vm.corollaryInfo[delivery.id].Spot
            ? vm.corollaryInfo[delivery.id].Spot.id
            : 'TRAFFIC';

        let spotIsci = vm.corollaryInfo[delivery.id].Spot ? vm.corollaryInfo[delivery.id].Spot.isci : 'NOISCI';

        // Build download links
        let endpoint = vm.EndPointService.assetDownloadEndpoint + '/';

        if (vm.downloadSelections.deliveredSpot) {
            vm.deliveredSpotDownloadLink = vm.downloadSelections.deliveredSpot
                ? endpoint +
                    '?' +
                    'assetUuid=' +
                    vm.downloadUuids.deliveredSpot +
                    '&' +
                    'baseFilename=' +
                    vm.corollaryInfo[delivery.id].spotDeliveryFilename +
                    '&' +
                    'authorization=' +
                    vm.loginService.getJwt()
                : '';

            vm.$window.open(vm.deliveredSpotDownloadLink);
        }

        if (vm.downloadSelections.originalSpot) {
            vm.spotDownloadLink = vm.downloadSelections.originalSpot
                ? endpoint +
                    '?' +
                    'assetUuid=' +
                    vm.downloadUuids.originalSpot +
                    '&' +
                    'baseFilename=' +
                    'spot_' +
                    placeholdId +
                    '_original' +
                    '&' +
                    'authorization=' +
                    vm.loginService.getJwt()
                : '';

            vm.$window.open(vm.spotDownloadLink);
        }
        if (vm.downloadSelections.distributionMaster) {
            vm.distributionMasterDownloadLink = vm.downloadSelections.distributionMaster
                ? endpoint +
                    vm.downloadUuids.distributionMaster +
                    '?' +
                    'baseFilename=' +
                    'spot_' +
                    spotIsci +
                    '_distributionMaster' +
                    '&' +
                    'authorization=' +
                    vm.loginService.getJwt()
                : '';

            vm.$window.open(vm.distributionMasterDownloadLink);
        }
        if (vm.downloadSelections.mezzanine) {
            vm.mezzanineDownloadLink = vm.downloadSelections.mezzanine
                ? endpoint +
                    vm.downloadUuids.mezzanine +
                    '?' +
                    'baseFilename=' +
                    'spot_' +
                    placeholdId +
                    '_mezzanine' +
                    '&' +
                    'authorization=' +
                    vm.loginService.getJwt()
                : '';

            vm.$window.open(vm.mezzanineDownloadLink);
        }
        if (vm.downloadSelections.quicktimeProxy) {
            vm.qtDownloadLink = vm.downloadSelections.quicktimeProxy
                ? endpoint +
                    vm.downloadUuids.quicktimeProxy +
                    '?' +
                    'baseFilename=' +
                    'spot_' +
                    placeholdId +
                    '_proxy' +
                    '&' +
                    'authorization=' +
                    vm.loginService.getJwt()
                : '';

            vm.$window.open(vm.qtDownloadLink);
        }
        if (vm.downloadSelections.slate) {
            vm.slateDownloadLink = vm.downloadSelections.slate
                ? endpoint +
                    vm.downloadUuids.slate +
                    '?' +
                    'baseFilename=' +
                    'spot_' +
                    placeholdId +
                    '_slate' +
                    '&' +
                    'authorization=' +
                    vm.loginService.getJwt()
                : '';

            vm.$window.open(vm.slateDownloadLink);
        }
        if (vm.downloadSelections.metadata) {
            vm.metaDownloadLink = vm.downloadSelections.metadata
                ? endpoint +
                    vm.downloadUuids.metadata +
                    '?' +
                    'baseFilename=' +
                    'spot_' +
                    placeholdId +
                    '_metadata' +
                    '&' +
                    'authorization=' +
                    vm.loginService.getJwt()
                : '';

            vm.$window.open(vm.metaDownloadLink);
        }
        if (vm.downloadSelections.mp3) {
            vm.metaDownloadLink = vm.downloadSelections.mp3
                ? endpoint +
                    vm.downloadUuids.mp3 +
                    '?' +
                    'baseFilename=' +
                    'spot_' +
                    placeholdId +
                    '_audio' +
                    '&' +
                    'authorization=' +
                    vm.loginService.getJwt()
                : '';

            vm.$window.open(vm.metaDownloadLink);
        }
    }
}
