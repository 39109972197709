/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

// THIS CONTROLLER MIGHT NEED TO BE DELETED, IT DOESN'T APPEAR TO BE USED ANYMORE.

export class DeliveryDetailListController {
    // Public bindable properties
    public paginationOptions: any;
    public currentId: any;
    public gridOptions: any;

    // Injectable values
    static get $inject() {
        return [
            '$scope',
            '$state',
            '$stateParams',
            '$timeout',
            'uiGridConstants',
            'DeliveryResourceFactory',
        ];
    }

    constructor(
        public $scope: any,
        public $state: any,
        public $stateParams: any,
        public $timeout: any,
        public uiGridConstants: any,
        public DeliveryResourceFactory: any
    ) {
        /* PRIVATE : DATA */
        //Declare all private variables here
        /*
         * This is the `vm` object. It is a direct reference to the controller
         * which acts as our 'view-model' in angular. It also limits our need
         * to be accessing $scope directly. */
        /*jshint validthis:true*/
        let vm = this;
        // Store the search params in memory as we go
        // This can remain a private variable because it is ONLY used in the constructor
        let SearchParams = {};

        /* BINDABLE : DATA */
        vm.paginationOptions = {
            pageNumber: 1,
            pageSize: 25,
            sort: null,
        };
        vm.currentId = null;

        vm.gridOptions = {
            paginationPageSizes: [25, 50, 75],
            paginationPageSize: 25,
            paginationCurrentPage: vm.paginationOptions.pageNumber,
            useExternalPagination: true,
            useExternalSorting: true,
            enableColumnMenus: false,
            enableSorting: false,
            enableRowSelection: true,
            enableRowHeaderSelection: false,
            modifierKeysToMultiSelect: true,
            multiSelect: true,
            appScopeProvider: vm,
            rowTemplate:
                '<div ng-class="{ \'current-detail-open\': row.entity.currentSelection }"><div ng-dblclick="grid.appScope.openDetail(row.entity)" mbl-dblclick="grid.appScope.openDetail(row.entity)" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader}"  ui-grid-cell></div></div>',
            columnDefs: [
                {
                    name: 'isci',
                    field: 'isci',
                    displayName: 'isci/spot #',
                    cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.isci}}</div>',
                },
            ],
            onRegisterApi(gridApi: any) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function (grid:any, sortColumns:any) {
                    if (sortColumns && sortColumns.length === 0) {
                        vm.paginationOptions.sort = null;
                    } else {
                        vm.paginationOptions.sort = sortColumns[0].sort.direction;
                    }
                    vm.getPage(SearchParams);
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage:any, pageSize:any) {
                    vm.paginationOptions.pageNumber = newPage;
                    vm.paginationOptions.pageSize = pageSize;
                    vm.getPage(SearchParams);
                });
            },
        };

        /* EVENTS */
        //Register any event listeners
        vm.$scope.$on('$stateChangeSuccess', function (
            event:any,
            toState:any,
            toParams:any,
            fromState:any,
            fromParams:any
        ) {
            // Wait for the current digest cycle to finish, so the grid will display properly
            vm.$timeout(function () {
                // Utilize parameters
                SearchParams = toParams;
                vm.currentId = toParams.id;
                vm.getPage(SearchParams);
            });
        });
    }

    /* IMPLEMENTATION : BINDABLE */
    backToSearch() {
        let vm = this;

        vm.$state.go(
            'deliveryList',
            { pageNumber: vm.paginationOptions.pageNumber },
            { notify: true }
        );
    }

    getPage(params:any) {
        let vm = this;

        params = params !== undefined ? params : {};
        params.limit = vm.paginationOptions.pageSize;
        params.offset = vm.paginationOptions.pageSize * (vm.paginationOptions.pageNumber - 1);

        vm.DeliveryResourceFactory.getAll(params, function (deliveries:any) {
            vm.gridOptions.totalItems = deliveries.data.count;
            for (let i = 0; i < deliveries.data.rows.length; i++) {
                if (deliveries.data.rows[i].id * 1 === vm.currentId * 1) {
                    deliveries.data.rows[i].currentSelection = true;
                }
            }
            vm.gridOptions.data = deliveries.data.rows;
        });
    }

    openDetail(row:any) {
        let vm = this;

        vm.$state.go('deliveryDetail', {
            id: row.id,
            pageNumber: vm.paginationOptions.pageNumber,
        });
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them
    /**
     * Initialization for this controller.
     */

    $onInit() {
        let vm = this;

        // Things to do on init
        if (vm.$stateParams.pageNumber && vm.$stateParams.pageNumber !== 0) {
            vm.paginationOptions.pageNumber = parseInt(vm.$stateParams.pageNumber, 10);
            vm.gridOptions.paginationCurrentPage = vm.paginationOptions.pageNumber;
        }
    }
}
