/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
import { deliveryDetail } from './deliveryDetail-controller';
import { DeliveryDetailListController } from './deliveryDetailList-controller'; // eslint-disable-line

import { DeliveryGridController } from './deliveryGrid-controller';
import { DeliverySearchController } from './deliverySearch-controller';
var dependencies = ['comcast.common.authentication', 'comcast.common.communication', 'comcast.common.navigation', 'comcast.deliveries', 'ngMaterial', 'ngMessages', 'comcast.directives', 'ui.router'];
var defaultName = angular.module('comcast.deliveries.detail', dependencies).component('deliveryDetail', deliveryDetail).config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state('deliveryList', {
    // Specify params that this state can access from the URL
    url: '/list?format&title&isci&deliveryStatus&orderNumber&promo&isLite&duration&updatedDateFrom&updatedDateTo&advertiser&agency&includeArchives&clearedFromDate&destinationName&houseId&runningFootage&orderItemId&type&action',
    parent: 'deliveries',
    // Specify default values for state params
    params: {
      title: null,
      isci: null,
      format: null,
      status: null,
      deliveryStatus: null,
      orderNumber: null,
      promo: null,
      isLite: null,
      duration: null,
      updatedDate: null,
      advertiser: null,
      brand: null,
      campaign: null,
      agency: null,
      includeArchives: null,
      sortField: null,
      sortDirection: null,
      sortType: null,
      clearedFromDate: null,
      destinationName: null,
      houseId: null,
      runningFootage: null
    },
    reloadOnSearch: true,
    resolve: {
      promoOnly: function promoOnly() {
        return false;
      }
    },
    views: {
      'deliveryContent@deliveries': {
        template: '<ui-view name="detailContent" class="detail-content"/>'
      },
      'deliverySearch@deliveries': {
        template: '<ui-view name="detailSearch" class="detail-search" />'
      },
      'detailContent@deliveryList': {
        template: require('./deliveryGrid-template.html'),
        controller: DeliveryGridController,
        controllerAs: 'vm'
      },
      'detailSearch@deliveryList': {
        template: require('./deliverySearch-template.html'),
        controller: DeliverySearchController,
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  }).state('promoList', {
    // Specify params that this state can access from the URL
    url: '/promos?format&title&isci&deliveryStatus&promoType&duration&updatedDateFrom&updatedDateTo&advertiser&brand&campaign&includeArchives&clearedFromDate&providerName&runningFootage',
    parent: 'deliveries',
    // Specify default values for state params
    params: {
      title: null,
      isci: null,
      format: null,
      status: null,
      deliveryStatus: null,
      promoType: null,
      duration: null,
      updatedDate: null,
      advertiser: null,
      brand: null,
      campaign: null,
      includeArchives: null,
      sortField: null,
      sortDirection: null,
      sortType: null,
      clearedFromDate: null,
      providerName: null,
      runningFootage: null,
      isFranchise: null
    },
    reloadOnSearch: true,
    resolve: {
      promoOnly: function promoOnly() {
        return true;
      }
    },
    views: {
      'deliveryContent@deliveries': {
        template: '<ui-view name="detailContent" class="detail-content"/>'
      },
      'deliverySearch@deliveries': {
        template: '<ui-view name="detailSearch" class="detail-search" />'
      },
      'detailContent@promoList': {
        template: require('./deliveryGrid-template.html'),
        controller: DeliveryGridController,
        controllerAs: 'vm'
      },
      'detailSearch@promoList': {
        template: require('./deliverySearch-template.html'),
        controller: DeliverySearchController,
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  }).state('deliveryDetail', {
    url: '/deliveries/:id',
    parent: 'deliveryList',
    params: {
      gridData: null,
      pageData: null
    },
    views: {
      detailContent: {
        template: '<delivery-detail></delivery-detail>'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  }).state('promoDetail', {
    url: '/promos/:id',
    parent: 'promoList',
    params: {
      gridData: null,
      pageData: null
    },
    views: {
      detailContent: {
        template: '<delivery-detail></delivery-detail>'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  });
}]).name;
export { defaultName as default, dependencies };